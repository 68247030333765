import React, {useCallback, useEffect, useState} from 'react';
import logo from './logo.svg';
import genericBrand from './genericbrand.png'
import Fuse from 'fuse.js'
import {debounce, result} from "lodash"

import data from './data2.json';

import './App.css';
import {createTheme, TextField, ThemeProvider} from "@mui/material";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
function App() {
  return (
      <ThemeProvider theme={darkTheme}>
      <div className="App">
      <header className="App-header">
        <h1>
          Plearch
        </h1>
      </header>
          <body>
          <SearchWithFuse/>
          </body>
    </div>
      </ThemeProvider>
  );
}

export default App;


const SearchWithFuse = () => {
    const [searchInput, setSearchInput] = useState('')
    const [searchResults, setSearchResults] = useState<Array<any>>([])
    let fuse: any
    useEffect(() => {
        const options = {
            includeScore: true,
            keys: [
            {
                name: 'BrandName',
                weight: 15,
            },
            {
                name: 'Aliases',
                weight: 10,
            },
                {
                name: 'URLs',
                weight: 1,
            }]
        }

        fuse = new Fuse(data, options)
    })
    useEffect(() => {
        search(searchInput)
    }, [searchInput])

    const search = useCallback(
        debounce(query => {
            const start = performance.now();
            const result = fuse.search(query, {limit: 10})
            setSearchResults(result)
            const end = performance.now();
            console.log(`Execution time: ${end - start} ms`);
        }, 100),
        []
    );

  return (
      <div>
          <TextField
              id="outlined-controlled"
              label="Search For Institution"
              value={searchInput}

              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchInput(event.target.value);
              }}
          />
          {searchResults.map((result) => {
              return (
                  <div className="Result">
                      <span className="LogoContainer">
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=genericBrand;
                            }
                            }  src={`https://d2vkwe09h0izlt.cloudfront.net/pdayLogo/${result.item.BrandID}.png`}/>
                      </span>
                          {result.item.BrandName}
                  </div>
              )
          })}
      </div>
      )
}